<template>
  <div class="global-search">
    <div class="filter">
      <span class="global-search-header">Filter</span>

      <div class="tag-selection">
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="resultSet.gremiums"
        >
          Gremien <span v-if="result">({{ totalForTag("GREMIUM") }})</span>
        </b-form-checkbox>
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="resultSet.news"
        >
          News <span v-if="result">({{ totalForTag("NEWS") }})</span>
        </b-form-checkbox>
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="resultSet.users"
        >
          Benutzer <span v-if="result">({{ totalForTag("USER") }})</span>
        </b-form-checkbox>
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="resultSet.comments"
        >
          Kommentare
          <span v-if="result">({{ totalForTag("TOPIC_COMMENT") }})</span>
        </b-form-checkbox>
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="resultSet.topics"
        >
          Beiträge <span v-if="result">({{ totalForTag("FORUM_TOPIC") }})</span>
        </b-form-checkbox>
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="resultSet.companies"
        >
          Firmen <span v-if="result">({{ totalForTag("COMPANY") }})</span>
        </b-form-checkbox>
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="resultSet.events"
        >
          Veranstaltungen
          <span v-if="result">({{ totalForTag("DSAG_EVENT") }})</span>
        </b-form-checkbox>
        <b-form-checkbox
          :value="true"
          :unchecked-value="false"
          v-model="resultSet.partners"
        >
          Partnerfirmen
          <span v-if="result">({{ totalForTag("PARTNER_PROFILE") }})</span>
        </b-form-checkbox>
      </div>
    </div>

    <ul class="results">
      <span class="global-search-header" v-if="result">
        Ergebnisse für <em>{{ result.search }}</em>
      </span>

      <template v-if="result">
        <em v-if="result.data.length == 0">Keine Ergebnisse gefunden</em>

        <GlobalSearchResult
          v-for="item in sliceVisibleResults(result.data)"
          :key="getIterationKey(item.item)"
          :value="item"
          tabindex="0"
        />

        <b-button
          class="all-results-btn"
          v-if="result?.paginatorInfo?.total - 4 > 0"
          @click="navigateToFullPage()"
          variant="orange"
        >
          Details, Filter & {{ result.paginatorInfo.total - 4 }} weitere
          Ergebnisse
        </b-button>
        <b-button
          class="all-results-btn"
          v-else
          @click="navigateToFullPage()"
          variant="orange"
        >
          Details & Filter
        </b-button>
      </template>
    </ul>
  </div>
</template>

<script>
import GlobalSearchResult from "./GlobalSearchResult.vue";
import gql from "graphql-tag";

const tagMapping = {
  GREMIUM: {
    index: "gremiums",
    name: "Gremien",
  },
  NEWS: {
    index: "news",
    name: "News",
  },
  USER: {
    index: "users",
    name: "Benutzer",
  },
  TOPIC_COMMENT: {
    index: "topic_comments",
    name: "Kommentare",
  },
  FORUM_TOPIC: {
    index: "forum_topics",
    name: "Beiträge",
  },
  FILE: {
    index: "files",
    name: "Dateien",
  },
  COMPANY: {
    index: "companies",
    name: "Firmen",
  },
  DSAG_EVENT: {
    index: "dsag_events",
    name: "Veranstaltungen",
  },
  PARTNER_PROFILE: {
    index: "partner_profiles",
    name: "Partnerfirmen",
  },
};

const tagNames = Object.keys(tagMapping);

function parseQuery(query) {
  const raw_page = parseInt(query.page, 10);
  const valid_page = typeof raw_page === "number" && !isNaN(raw_page);

  const tags = (query.tags ?? "").split(",").filter((key) => {
    return tagNames.includes(key);
  });

  const raw_count = parseInt(query.count, 10);
  const valid_count = typeof raw_count === "number" && !isNaN(raw_count);

  return {
    page: valid_page ? raw_page : 1,
    count: valid_count ? raw_count : 10,
    tags: tags,
  };
}

export default {
  name: "GlobalSearchPreview",
  props: ["searchQuery", "previewMode"],
  components: {
    GlobalSearchResult,
  },
  data() {
    return {
      availableTags: tagNames,
      page: 1,
      count: 4,
      resultSet: {
        gremiums: false,
        news: false,
        users: false,
        comments: false,
        topics: false,
        files: false,
        companies: false,
        events: false,
        partners: false,
      },
    };
  },
  watch: {
    tags() {
      if (!this.previewMode) {
        const query = Object.assign({}, this.$route.query, {
          tags: this.tags.join(","),
        });

        this.$router.push({
          path: this.$route.path,
          query: query,
        });
      }
    },
    $route() {
      const query = parseQuery(this.$route.query);

      if (this.previewMode) {
        this.page = query.page;
        this.count = query.count;
        this.tags = query.tags;
      }
    },
  },
  methods: {
    tagToIndex: (tag) => tagMapping[tag]?.index,
    tagName: (tag) => tagMapping[tag]?.name,
    totalForTag(tag) {
      const key = this.tagToIndex(tag);
      const totals = this.result.totalPerIndex;
      return key in totals ? totals[key] : 0;
    },
    getIterationKey(item) {
      if (!("id" in item)) {
        throw new Error(`Cannot get iteration key for ${item}`);
      }

      return item.id;
    },
    sliceVisibleResults(results) {
      // TODO Determine the maximum amount of items via the available space.
      return this.previewMode ? results.slice(0, 4) : results;
    },
    showMoreVisible(results) {
      const everythingVisible =
        results.length == this.sliceVisibleResults(results).length;

      return this.previewMode && !everythingVisible;
    },
    navigateToFullPage() {
      this.$router.push({
        name: "global-search",
        query: {
          query: this.searchQuery,
          page: 1,
          filter: JSON.stringify(this.searchFilter),
        },
      });
    },
  },
  apollo: {
    result: {
      fetchPolicy: "network-only",
      prefetch: false,
      query: gql`
        query GlobalSearch(
          $page: Int
          $count: Int
          $search: GlobalSearchFilters
        ) {
          globalSearch(page: $page, count: $count, search: $search) {
            data {
              item {
                __typename
                ... on Gremium {
                  id
                  name
                  created_at
                  route_slug
                  shortened_type
                }
                ... on News {
                  id
                  title
                  route_slug
                  created_at
                  gremium {
                    id
                    route_slug
                  }
                }
                ... on User {
                  id
                  first_name
                  last_name
                  email
                  created_at
                  company {
                    id
                    name1
                  }
                }
                ... on TopicComment {
                  id
                  created_at
                  user {
                    id
                    first_name
                    last_name
                  }
                  content {
                    id
                    text_content
                  }
                  topic {
                    id
                    route_slug
                    title
                    forum {
                      id
                      gremium_route_slug
                    }
                  }
                }
                ... on File {
                  id
                  name
                  description
                  created_at
                }
                ... on Company {
                  id
                  name1
                  name2
                  created_at
                }
                ... on CompanyPartnerProfile {
                  id
                  created_at
                  comp: company {
                    id
                    name1
                    name2
                  }
                }
                ... on ForumTopic {
                  id
                  title
                  route_slug
                  created_at
                  forum {
                    id
                    gremium_route_slug
                  }
                }
                ... on DsagEvent {
                  id
                  name
                  route_slug
                  start
                }
              }
            }
            totalPerIndex {
              companies
              dsag_events
              files
              forum_topics
              gremiums
              news
              partner_profiles
              topic_comments
              users
            }
            paginatorInfo {
              total
              count
              currentPage
              perPage
            }
          }
        }
      `,
      update(data) {
        return data.globalSearch;
      },
      debounce: 300,
      variables() {
        return {
          page: this.page,
          count: this.count,
          search: this.searchFilter,
        };
      },
    },
  },
  computed: {
    searchFilter() {
      const def = {
        company: {
          enabled: true,
        },
        event: {
          enabled: true,
        },
        file: {
          enabled: true,
        },
        gremium: {
          enabled: true,
        },
        news: {
          enabled: true,
        },
        partner_profile: {
          enabled: true,
        },
        topics_and_comments: {
          comments_enabled: true,
          topics_enabled: true,
        },
        user: {
          enabled: true,
        },
        fulltext: this.searchQuery,
      };
      if (!Object.values(this.resultSet).includes(true)) return def;
      return {
        company: {
          enabled: this.resultSet.companies,
        },
        event: {
          enabled: this.resultSet.events,
        },
        file: {
          enabled: this.resultSet.files,
        },
        gremium: {
          enabled: this.resultSet.gremiums,
        },
        news: {
          enabled: this.resultSet.news,
        },
        partner_profile: {
          enabled: this.resultSet.partners,
        },
        topics_and_comments: {
          comments_enabled: this.resultSet.comments,
          topics_enabled: this.resultSet.topics,
        },
        user: {
          enabled: this.resultSet.users,
        },
        fulltext: this.searchQuery,
      };
    },
  },
};
</script>
